<template>
  <div class="wrapper">
    <Sidebar :class="!isOpenSidebar ? 'close-sidebar': ''" />
    <div id="wrapper-content" :class="!isOpenSidebar ? 'full-wrapper-content' : ''">
      <PageHeader :isListPage="true" @toggleSidebar="toggleSidebar" />
      <div class="dashboard-wrapper">
				<div class="content-top-bar">
					<div class="row align-items-center">
						<div class="col-sm-3 col-md-6 ">
							<!-- <h5>Sites</h5> -->
              <input v-model="searchText" type="text" class="text-input search-site-text" placeholder="Search Sites by Title or URI">
						</div><!-- 6 -->
						<div class="col-sm-9 col-md-6">
							<div class="content-top-right">
								<div class="form-check">
									<div class="archive_url">
										<input v-model="siteName" type="text" placeholder="Site URI">
										<a @click="goToSite(siteName)" href="#" class="btn btn-default btn-bla ck">Create</a>
									</div>
								</div>
								<div class="form-check">
									<div class="archiv_btn pointer">
										<input class="form-check-input"  v-model='archivedOnly' type="checkbox" value="" id="flexCheckChecked">
										<label class="form-check-label archived-label" for="flexCheckChecked">
											Archived
										</label>
									</div>
								</div>
							</div>
						</div><!-- 6 -->
					</div><!-- row -->
				</div><!-- content-top-bar -->

				<div class="dashboard-table" id="sites-table">
          <div  v-if="loading ||  copying" class="bg-gray-500 bg-opacity-25 fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div  v-else-if="filteredSites.length === 0" class="h-screen flex items-center justify-center">
          <div class="text-lg">
            {{ archivedOnly ? "No archived sites" : "No sites yet" }}
          </div>
        </div>
					<div class="table-responsive">
						<table class="table">
							<tbody>
								<tr v-for="site of filteredSites"
                :key="site._id">
									<td>{{ site.title || site.uri }} <span v-if="site.uri">({{ site.uri }})</span></td>
									<td>
										<div class="dashboard-table-icons">
											<h6>{{site.defaultQuery}}</h6>
											<div class="pointer action-btn"  @click="goToSite(site.uri)" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Edit"
												><i class="fa-solid fa-pen-to-square"></i></div>
											<!-- <a data-bs-toggle="tooltip" data-bs-placement="bottom"
												data-bs-title="Permission" ><i class="fa-solid fa-key"></i></a> -->
                        <div class="pointer action-btn" @click="openSitePage(site)" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="View"
												><i class="fa-solid fa-eye"></i></div>
                        <div class="pointer action-btn" @click="dupSite(site)" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Copy site"
												><i class="fa-solid fa-copy"></i></div>
											<div class="pointer action-btn" @click="archive(site)" data-bs-toggle="tooltip" data-bs-placement="bottom"
												data-bs-title="File Open"><i class="fa-solid fa-box-archive"></i></div>
										</div><!-- dashboard-table-icons -->
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- <nav aria-label="Page navigation">
						<ul class="pagination justify-content-center mb-0">
							<li class="page-item disabled">
								<a class="page-link"><i class="fa-solid fa-chevron-left"></i></a>
							</li>
							<li class="page-item"><a class="page-link" href="#">1</a></li>
							<li class="page-item"><a class="page-link" href="#">2</a></li>
							<li class="page-item"><a class="page-link" href="#">3</a></li>
							<li class="page-item">
								<a class="page-link" href="#"><i class="fa-solid fa-chevron-right"></i></a>
							</li>
						</ul>
					</nav> -->
				</div><!-- dashboard-table -->

			</div><!-- dashboard-wrapper -->
    </div>
  </div>
</template>
<script>
import PageHeader from './components/PageHeader'
import Sidebar from '@/components/Sidebar.vue'
import * as siteService from '../service/site'
import { viewer_url } from '../constants'

export default {
  name: 'SitesList',
  components: {
    PageHeader,
    Sidebar
  },
  data () {
    return {
      loading: false,
      siteName: '',
      sites: [],
      copying: false,
      archivedOnly: false,
      searchText: '',
      isOpenSidebar: true,
    }
  },
  mounted () {
    this.loadData()
  },
  watch: {
    archivedOnly () {
      this.loadData()
    }
  },
  computed:{
    filteredSites(){
      let search = this.searchText.trim().toLowerCase();
      if (search) {
        return this.sites.filter(site => site?.title?.toLowerCase()?.includes(search) || site?.uri?.toLowerCase()?.includes(search))
      }
      return this.sites;
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      try {
        this.sites = await siteService.getSites(this.archivedOnly)
      } catch (err) {
        console.log('err: ', err);
      }
      this.loading = false
    },
    goToSite (name) {
      if (!name) { return }
      window.location.href = `/site-edit/${name}`
    },
    openSitePage (site) {
      // const query = document.querySelector(`#querystring-${site._id}`).value
      const url = `${viewer_url}/${site.uri}?${site.defaultQuery}`
      window.open(url)
    },
    async archive (site) {
      const confirm = window.confirm(`Are you sure want to archive ${site.uri}?`)
      if (!confirm) { return }
      await siteService.archiveSite(site.uri)
      this.loadData()
    },
    async unArchive (site) {
      await siteService.unArchiveSite(site.uri)
      this.loadData()
    },
    async dupSite (site) {
      this.copying = true
      const newSiteUri = window.prompt('New Site Uri', `${site.uri}-copy`)
      try {
        await siteService.duplicateSite(site.uri, newSiteUri)
        this.loadData()
      } catch (err) {
        this.$notify({
          type: "warn",
          text: 'URI is already used. please use new uri',
        });
      }
      this.copying = false
    },
    toggleSidebar(){
      this.isOpenSidebar = !this.isOpenSidebar
    }
  }
}
</script>
<style>
.full-wrapper-content{
  margin-left: 0px !important;
}
.close-sidebar{
  margin-left: -260px !important;
}
.search-site-text{
  width: 100%;
  max-width: 300px;
}
.archived-label{
  margin-left: 4px;
}
#sites-table{
  height: calc(100vh - 156px);
  overflow: auto;
}
</style>
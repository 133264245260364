<template>
  <div class="side-bar-card mt-1">
    <div class="info-card-label">
      <label class="mr-auto">Data</label>
      <font-awesome-icon
        icon="info"
        class="mr-3 cursor-pointer"
        title="Data variables used within component."
      />
    </div>
    <div
      v-for="source of filterredSources"
      :key="source.type"
    >
      <div class="flex pt-2">
        <h3 class="mr-auto">{{ source.label}}</h3>
        <font-awesome-icon
          icon="info"
          class="mr-3 cursor-pointer"
          :title="source.helpText"
        />
      </div>
      <div
        v-for="(item) of source.data"
        :key="`${item.idx}-${data.length}`"
        class="flex flex-col border-b pt-2"
        :class="{
          'border-t': idx === 0
        }"
      >
        <Accordion :title="item.key" cClass="">
          <DataItemEdit
            :item="item"
            @update:item="$emit('update', { idx: item.idx, data: $event })"
            @remove:item="$emit('remove', item.idx)"
          />
        </Accordion>
      </div>
      <div class="flex mt-3 w-full side-bar-input">
        <input type="text" :class="`min-w-0 flex-1 new-name-${source.type}`" :placeholder="`New ${source.type} data item`" />
        <button
          class="ml-2 cursor-pointer"
          @click="add(source.type)"
        >
          Add
      </button>
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from '../ui/Accordion.vue'
import DataItemEdit from './DataItemEdit.vue'

export default {
  name: 'DataSource',
  components: {
    Accordion,
    DataItemEdit
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    filterredSources () {
      return [
        {
          label: 'Remote Data Source',
          type: 'remote',
          data: this.data.map((d, idx) => ({ ...d, idx })).filter(item => item.type === 'remote'),
          helpText: "Remote data source fetched from remote api endpoint."
        }, {
          label: 'Const Data Source',
          type: 'const',
          data: this.data.map((d, idx) => ({ ...d, idx })).filter(item => item.type === 'const'),
          helpText: "Regular variable used within component."
        }
      ]
    }
  },
  methods: {
    add (type) {
      console.log('type: ', type);
      const name = document.querySelector(`.new-name-${type}`).value
      document.querySelector(`.new-name-${type}`).value = ''
      this.$emit('add', {
        name,
        type
      })
      this.newName = ''
    }
  }
}
</script>
<template>
  <div class="data-source-edit mr-2" :class="item.key">
    <div class="flex my-1">
      <label class="w-1/4"> name </label>
      <div class="w-3/4">
        <input :value="item.key" @input="setField('key', $event.target.value)" type="text" class="w-full" />
      </div>
    </div>
    <div class="hidden mb-1">
      <label class="w-1/4"> type </label>
      <div class="w-3/4">
        <select :value="item.type" @input="setField('type', $event.target.value)" class="w-full">
          <option v-for="item of types" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex flex-col mb-1">
      <label> default value </label>
      <ZoomView
        :class="{
          'text-red-500 border-red-500 border-b': errors.default_value
        }"
        :title="errors.default_value"
      >
        <textarea
          class="w-full h-full default-value-text-area"
          :defaultValue="JSON.stringify(item.default_value, '', 2)"
          @input="setDefaultValue($event.target.value)"
        />
      </ZoomView>
    </div>
    <div v-if="item.type === 'remote'">
      <label class="font-semibold"> remote data resolve </label>
      <div class="mb-1">
        <label class="flex items-center">
          Make api call on remote
          <input class="ml-2" type="checkbox" :checked="item.use_relay" @input="setField('use_relay', $event.target.checked)" />
        </label>
      </div>
      <div class="mb-1 py-1">
        <label>source api url</label>
        <font-awesome-icon
          icon="info"
          class="ml-3 cursor-pointer opacity-70"
          title="Data source api url. url can include variabled using :variable format"
        />
        <div class="flex py-1">
            <div class="w-1/3">Method</div>
            <div class="w-1/2">URL</div>
          </div>
        <div class="flex">
          <select :value="item.method" @input="setField('method', $event.target.value)">
            <option v-for="item of ['GET', 'POST']" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
          <input
            :defaultValue="item.resolve.source_url"
            type="text"
            class="w-full"
            :class="{
              'text-red-500 border-red-500 border-b': errors.source_url
            }"
            placeholder="https://example.com"
            :title="errors.source_url"
            @input="setSourceUrl($event.target.value)"
          />
      </div>
      </div>
      <div class="mb-1 mt-1">
        <label>source url params</label>
        <font-awesome-icon
          icon="info"
          class="ml-3 cursor-pointer opacity-70"
          title="Url parameters used within api url"
        />
        <div>
          <div class="flex">
            <div class="w-1/3">name</div>
            <div class="w-1/3">type</div>
            <div class="w-1/3">default value</div>
          </div>
          <div
            v-for="(param, idx) of item.resolve.url_params"
            :key="idx"
            class="flex"
          >
            <div class="flex" :class="{
              'text-red-500 border-red-500 border-b': errors.params[idx]
            }" :title="errors.params[idx]">
              <input :defaultValue="param.name" @input="setRequestValue('url_params', idx, 'name', $event.target.value)" type="text" class="w-1/3" />
              <select :value="param.source" @input="setRequestValue('url_params', idx, 'source', $event.target.value)" class="w-1/3">
                <option v-for="ut of apiUrlParamTypes" :key="ut">{{ut}}</option>
              </select>
              <input :defaultValue="param.default_value" @input="setRequestValue('url_params', idx, 'default_value', $event.target.value)"
                type="text" class="w-1/3" placeholder="Default value" />
            </div>
            <span class="cursor-pointer hover:opacity-80 opacity-30 ml-1"
              @click="removeRequestData('url_params', idx)"
            >
              <font-awesome-icon icon="minus" />
            </span>
          </div>
          <div class="flex mt-1">
            <div class="flex">
              <input ref="param_name" placeholder="name" type="text" class="w-1/3" />
              <select ref="param_source" class="w-1/3">
                <option v-for="ut of apiUrlParamTypes" :key="ut">{{ut}}</option>
              </select>
              <input ref="param_defaultValue" type="text" class="w-1/3" placeholder="Default value" />
            </div>
            <span class="cursor-pointer hover:opacity-80 opacity-30 ml-1"
              @click="addParam()"
            >
              <font-awesome-icon icon="plus" />
            </span>
          </div>
        </div>
      </div>
      <div class="my-1">
        <label>Request Headers</label>
        <font-awesome-icon
          icon="info"
          class="ml-3 cursor-pointer opacity-70"
          title="Url parameters used within api url"
        />
        <div>
          <div class="flex">
            <div class="w-1/2">name</div>
            <div class="w-1/2">default value</div>
          </div>
          <div
            v-for="(param, idx) of item.resolve.headers"
            :key="idx"
            class="flex"
          >
            <div class="flex">
              <input :defaultValue="param.name" @input="setRequestValue('headers', idx, 'name', $event.target.value)" type="text" class="w-1/2" />
              <input :defaultValue="param.default_value" @input="setRequestValue('headers', idx, 'default_value', $event.target.value)"
                type="text" class="w-1/2" placeholder="Default value" />
            </div>
            <span class="cursor-pointer hover:opacity-80 opacity-30 ml-1"
              @click="removeRequestData('headers', idx)"
            >
              <font-awesome-icon icon="minus" />
            </span>
          </div>
          <div class="flex mt-1">
            <div class="flex">
              <input ref="header_name" type="text" placeholder="name" class="w-1/2" />
              <input ref="header_defaultValue" type="text" class="w-1/2" placeholder="Default value" />
            </div>
            <span class="cursor-pointer hover:opacity-80 opacity-30 ml-1"
              @click="addHeader()"
            >
              <font-awesome-icon icon="plus" />
            </span>
          </div>
        </div>
      </div>
      <div class="py-1">
        <label>response section</label>
        <input
          :defaultValue="item.resolve.section"
          type="text"
          class="w-full"
          :class="{
            'text-red-500 border-red-500 border-b': errors.section
          }"
          :title="errors.section"
          @input="editResSection($event.target.value)"
        />
      </div>
      <div class="mb-1">
        <DataSourceTest :item="item" />
      </div>
    </div>
    <div class="mb-1 flex">
      <button
        class="hover:text-red-500 ml-auto text-xs"
        @click="removeItem"
      >Delete This Data Item</button>
    </div>
  </div>
</template>
<script>
import ZoomView from '../ui/ZoomView.vue'
import DataSourceTest from './DataSourceTest.vue'

const types = [
  'remote',
  'const'
]
const apiUrlParamTypes = [
  'query',
  'prop',
  'data'
]
export default {
  name: "DataItemEdit",
  components: {
    ZoomView,
    DataSourceTest
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.validate()
      }
    }
  },
  data () {
    return {
      types,
      apiUrlParamTypes,
      errors: {
        params: []
      }
    }
  },
  methods: {
    validate () {
      if (this.item.resolve && this.item.resolve.source_url) {
        const urlParams = this.item.resolve.source_url.split(':').slice(2).map(p => {
          return p.split(/\/|\?|=|,|&|\./)[0]
        })
        this.item.resolve.url_params.forEach((param, idx) => {
          if (!urlParams.includes(param.name)) {
            this.errors.params[idx] = `This param isn't used on the url`
          } else {
            this.errors.params[idx] = ''
          }
        })
        const paramNames = this.item.resolve.url_params.map(p => p.name)
        const missingParams = urlParams.filter(param => !paramNames.includes(param))
        if (missingParams.length > 0) {
          this.errors.source_url = 'add following url params: ' + missingParams.join(',')
        } else {
          this.errors.source_url = ''
        }
        if (!this.item.resolve.section.startsWith('res')) {
          this.errors.section = "Make sure this values starts with 'res'"
        } else {
          this.errors.section = ''
        }
      }
    },
    setField(field, value) {
      const clone = JSON.parse(JSON.stringify(this.item))
      clone[field] = value
      this.$emit('update:item', clone)
    },
    setDefaultValue(valueString) {
      try {
        const data = JSON.parse(valueString.replace(/\n/g, ''))
        this.setField('default_value', data)
        this.errors.default_value = ''
      } catch (err) {
        this.errors.default_value = err
      }
    },
    setSourceUrl(url) {
      const clone = JSON.parse(JSON.stringify(this.item))
      clone.resolve.source_url = url
      this.$emit('update:item', clone)
    },
    setRequestValue(type, idx, field, value) {
      const clone = { ...this.item }
      clone.resolve[type][idx][field] = value
      this.$emit('update:item', clone)
    },
    addParam () {
      const clone = JSON.parse(JSON.stringify(this.item))
      clone.resolve.url_params.push({
        name: this.$refs.param_name.value,
        source: this.$refs.param_source.value,
        default_value: this.$refs.param_defaultValue.value
      })
      this.$emit('update:item', clone)
      this.$refs.param_name.value = ''
      this.$refs.param_defaultValue.value = ''
    },
    addHeader() {
      const clone = JSON.parse(JSON.stringify(this.item))
      if (!clone.resolve.headers) {
        clone.resolve.headers = []
      }
      clone.resolve.headers.push({
        name: this.$refs.header_name.value,
        default_value: this.$refs.header_defaultValue.value
      })
      this.$emit('update:item', clone)
      this.$refs.header_name.value = ''
      this.$refs.header_defaultValue.value = ''
    },
    removeRequestData (type, idx) {
      const clone = JSON.parse(JSON.stringify(this.item))
      clone.resolve[type].splice(idx, 1)
      this.$emit('update:item', clone)
    },
    removeItem () {
      if (window.confirm('Are you sure?')) {
        this.$emit('remove:item')
      }
    },
    editResSection (section) {
      const clone = JSON.parse(JSON.stringify(this.item))
      clone.resolve.section = section
      this.$emit('update:item', clone)
    }
  }
};
</script>

<style scoped>
>>> input,
>>> select,
>>> textarea {
  /* background-color: rgba(0,0,0,0.1); */
  /* width: 100%; */
  padding: 5px 15px;
  background-color: #ffffff;
  border: 1px solid #f0f0ff;
  outline: 0;
}
</style>

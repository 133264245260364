import { api_host } from '../constants'
import jwtDecode from 'jwt-decode'

export const getUsers = async () => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/auth/list`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const loginApi = (email, password) => {
  return new Promise((resolve, reject) => {
    fetch(`${api_host}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email, password
      })
    })
      .then(resp => resp.json())
      .then(res => {
        if (res.success) {
          window.localStorage.setItem('token', res.token)
          resolve(res.token)
        } else {
          reject(res.error)
        }
      })
      .catch(err => reject(err))
  })
}

export const registerApi = async (email, password) => {
  return fetch(`${api_host}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email, password
    })
  }).then(res => res.json())
}

export const verifyToken = async () => {
  const token = window.localStorage.getItem('token')
  const resp = await fetch(`${api_host}/auth/user/me`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const updateUser = async (id, fields) => {
  const token = window.localStorage.getItem('token')
  const resp = await fetch(`${api_host}/auth/user/${id}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields)
  })
  return await resp.json()
}

export const getUser = () => {
  try {
    const token = window.localStorage.getItem('token')
    const user = token ? jwtDecode(token) : null
    return user
  } catch (e) {
    return null
  }
}

export const requestResetPassword = async (email) => {
  return fetch(`${api_host}/auth/request-reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email
    })
  }).then(res => res.json())
}

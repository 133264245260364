<template>
  <div id="toggle-sidebar">
    <figure class="sidebar-logo">
      <a href="/"><img src="@/assets/main-logo.png" class="img-fluid" /></a>
    </figure>
    <div class="navbar-nav">
      <RouterLink
        v-for="item in sideBarList"
        :key="item.text"
        class="nav-link"
        @click="activeLink = item.to"
        :to="item.to"
        :class="{'active': activeLink == item.to }"
        ><i :class="item.icon"></i> {{ item.text }}</RouterLink
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {
      sideBarList: [
        {
          text: "My Account",
          to: "",
          icon: "fa-solid fa-user",
        },
        {
          text: "Sites",
          to: "site-list",
          icon: "fa-solid fa-globe",
        },
        {
          text: "User Management",
          to: "users",
          icon: "fa-solid fa-user-gear",
        },
        {
          text: "Site Management",
          to: "",
          icon: "fa-solid fa-link",
        },
        {
          text: "Settings",
          to: "",
          icon: "fa-solid fa-gear",
        },
      ],
      activeLink: "site-list",
    };
  },
  mounted(){
    console.log("mounted", this.$route.path)
    this.activeLink = this.$route.path.replace("/", "")

  }
};
</script>
